import React from 'react'
import Page from '../components/Page'

const EvaluationPage = () => (
  <Page title="EVALUATION" image="/img/106.jpg">
    <p className="mt-8 w-2/3 text-center">
      NO CHANGES AFTER FILING - Except for amendments on obvious typing errors and originally disclosed matter translations are not subject to change after filing with the patent offices.<br/>
      <br/>
      Our technical linguists proofread your translations with feedback, monitor, comment, correct and/or reverse translate upon your request<br/>
    </p>
  </Page>
)

export default EvaluationPage